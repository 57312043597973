import { getCookie } from '@heytutor/core'
import { AUTH_ENDPOINT } from 'utils/config'
import { getAuthenticationState } from '../stores'
import { executeRequest, RequestMethod } from './api'
import { showInfoMessage } from './notifications'
import { goToLogin } from './routings'

const channel = new BroadcastChannel('heytutor_channel')

channel.onmessage = (event) => {
  switch (event.data) {
    case 'logOut':
      logOut(false, false)
      break
  }
}

export async function authorizeUser() {
  const { setUser } = getAuthenticationState()

  try {
    await executeRequest('/sanctum/csrf-cookie', { baseUrl: AUTH_ENDPOINT })

    const userData = await executeRequest('/api/user', { baseUrl: AUTH_ENDPOINT })
    const sessionDataCookie = getCookie('heytutor_session_data')

    if (!sessionDataCookie) {
      throw new Error('No session data recieved')
    }

    const sessionData = JSON.parse(sessionDataCookie)
    const { sessionExpirationTimestamp } = sessionData
    const sessionExpirationDate = new Date(sessionExpirationTimestamp * 1000)

    handleLogout(sessionExpirationDate)

    setUser({
      id: userData.id,
      publicId: userData.publicId,
      role: userData.roleId,
      firstName: userData.profile.firstName,
      lastName: userData.profile.lastName,
      email: userData.email,
      avatarUrl: userData.avatarUrl,
      district: { id: userData.profile.schoolId, name: userData.profile.districtName },
      school: { id: userData.profile.specificSchoolId }
    })
  } catch (e) {
    logOut()
  }
}

export async function logOut(logOutOnServer = true, logOutOnOtherWindows = true) {
  const { setUser } = getAuthenticationState()

  // Clear session data
  setUser(null)

  // Send Logout message to all other open windows
  if (logOutOnOtherWindows) {
    channel.postMessage('logOut')
  }

  // Logout from server
  if (logOutOnServer) {
    try {
      await executeRequest('/api/user/logout', {
        baseUrl: AUTH_ENDPOINT,
        method: RequestMethod.POST
      })
    } catch (e) {}
  }

  // Redirect to login
  goToLogin()
}

function handleLogout(expirationDate: Date) {
  let logoutWarningTask: NodeJS.Timeout | null = null
  let logoutTask: NodeJS.Timeout | null = null

  const cancelLogoutTimers = () => {
    if (logoutWarningTask != null) {
      clearTimeout(logoutWarningTask)
    }

    if (logoutTask != null) {
      clearTimeout(logoutTask)
    }
  }

  const scheduleLogoutTimers = () => {
    const currentDate = new Date()
    const millisecondsToExpiration = Math.max(0, expirationDate.getTime() - currentDate.getTime())
    const millisecondsToExpirationWarning = millisecondsToExpiration - 1 * 60 * 1000

    cancelLogoutTimers()

    if (millisecondsToExpirationWarning > 0) {
      logoutWarningTask = setTimeout(() => {
        showInfoMessage('Your session is about to expire and you will be logged out.', 30000)
      }, millisecondsToExpirationWarning)
    }

    if (millisecondsToExpiration > 0) {
      logoutTask = setTimeout(() => {
        logOut()
      }, millisecondsToExpiration)
    } else {
      logOut()
    }
  }

  window.addEventListener('blur', cancelLogoutTimers)
  window.addEventListener('focus', scheduleLogoutTimers)
  scheduleLogoutTimers()
}
